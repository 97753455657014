import * as React from 'react'
import { Formik, Form, Field, FormikHelpers as FormikActions } from 'formik';
import axios from 'axios';

import {
  alert,
  alertSuccess,
  alertError,
  styledForm,
  styledInput,
  styledSubmit
} from '../../styles/forms';

interface Values {
  email: string
}

const KlaviyoInlineForm: React.FC<{}> = (props) => {
  const initialValues: Values = { email: '' }

  return (
    <>
      <Formik
        initialValues={initialValues}
        onSubmit={(
          values: Values,
          { setStatus, resetForm }: FormikActions<Values>
        ) => {
          const formData = new FormData();

          formData.append('email', values.email);
          formData.append('g', props.formKey);

          axios({
            url: 'https://manage.kmail-lists.com/ajax/subscriptions/subscribe',
            method: 'post',
            data: formData,
            headers: {
              'content-type': 'multipart/form-data',
              'cache-control': 'no-cache'
            }
          })
          .then(res => {
            setStatus(res.status)
            if (res.status === 200) {
              resetForm()
              setStatus({
                sent: true,
                msg: 'Thank you for subscribing!'
              })
            }
          })
          .catch(err => {
            resetForm()
            setStatus({
              sent: false,
              msg: `There was a problem adding you to our list. Contact sayhi@jihi.com for help`
            })
          })
        }}
      >
        {(props) => (
          <>
          <Form css={styledForm}>
            <Field
              id='email'
              name='email'
              type='email'
              required={true}
              css={styledInput}
              placeholder='Enter your email'
              onChange={props.handleChange}
              value={props.values.email}
            />

            <button
              type='submit'
              className='Button Button--primary'
              css={styledSubmit}
              disabled={props.isSubmitting}
            >
              {/* Submit {props.isSubmitting && <span>Sending...</span>} */}
              Submit
            </button>
          </Form>

          {props.status && props.status.msg && (
            <p
              css={[alert, props.status.sent ? alertSuccess : alertError]}
            >
              {props.status.msg}
            </p>
          )}
          </>
        )}
      </Formik>
    </>
  )
}

export default KlaviyoInlineForm;
