import React from 'react';

export const Logo: React.FC = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 748.64 159.8" style={props.style}>
    <path d="M243.18,678.19a38.62,38.62,0,0,1-38.58-38.57,5.52,5.52,0,0,1,11,0,27.55,27.55,0,0,0,55.1,0V556.86a5.51,5.51,0,0,1,11,0v82.76A38.61,38.61,0,0,1,243.18,678.19Z" transform="translate(-204.6 -518.39)"/>
    <path d="M308,645a5.51,5.51,0,0,1-5.51-5.51V556.86a5.51,5.51,0,0,1,11,0v82.66A5.51,5.51,0,0,1,308,645Z" transform="translate(-204.6 -518.39)"/>
    <path d="M437.58,645a5.51,5.51,0,0,1-5.51-5.51V556.86a5.51,5.51,0,0,1,11,0v82.66A5.51,5.51,0,0,1,437.58,645Z" transform="translate(-204.6 -518.39)"/>
    <path d="M405.84,645a5.51,5.51,0,0,1-5.51-5.51V590a27.55,27.55,0,1,0-55.1,0v49.5a5.51,5.51,0,1,1-11,0V523.9a5.51,5.51,0,1,1,11,0V563a38.53,38.53,0,0,1,66.12,27v49.5A5.51,5.51,0,0,1,405.84,645Z" transform="translate(-204.6 -518.39)"/><path d="M564,606.65c0-1.11-.32-1.84-1.7-1.84h-8.59a2.45,2.45,0,0,1-2.62-2.75c0-1.64.85-2.88,2.62-2.88h10.56c3.86,0,5.37,1.7,5.37,5.7v18.48a2.51,2.51,0,0,1-2.75,2.82c-1.77,0-2.82-.92-2.82-2.43v-1.7a19.29,19.29,0,0,1,.2-2.69,10.22,10.22,0,0,1-1.91,2.49,17.93,17.93,0,0,1-12,4.46c-14.42,0-23-10.68-23-26.54,0-15.34,8.52-26.48,22.81-26.48,10.94,0,16.12,6,18.41,11a2.81,2.81,0,0,1,.33,1.37,2.89,2.89,0,0,1-2,2.62,4.84,4.84,0,0,1-1.64.33,2.75,2.75,0,0,1-2.69-1.77c-1.77-3.93-5-7.8-12.32-7.8-10,0-16.32,8.13-16.32,20.84,0,13.24,6.75,20.78,16.58,20.78,7.28,0,13.5-4.85,13.5-12.52Z" transform="translate(-204.6 -518.39)"/>
    <path d="M583.57,576.57c0-1.84,1.31-2.69,3.08-2.69s3.21.85,3.21,2.69V623c0,1.77-1.38,2.69-3.21,2.69s-3.08-.92-3.08-2.69Z" transform="translate(-204.6 -518.39)"/>
    <path d="M638.35,575.78a2.76,2.76,0,0,1,2.82-1.9,7.75,7.75,0,0,1,1.18.13,3,3,0,0,1,2.43,2.69,2.64,2.64,0,0,1-.2,1l-16.91,45.09a4.78,4.78,0,0,1-8.85-.06L602,577.81a2.81,2.81,0,0,1,2.23-3.8,3.73,3.73,0,0,1,1.18-.19,2.82,2.82,0,0,1,2.82,1.9l12.78,36c.85,2.42,1.57,5.43,2.29,8.06.72-2.69,1.51-5.64,2.36-8.13Z" transform="translate(-204.6 -518.39)"/>
    <path d="M662.86,617.53c0,1.51,1,2.09,2.43,2.09h23.46a2.53,2.53,0,0,1,2.62,2.82,2.62,2.62,0,0,1-2.62,2.89H663.39c-4.66,0-6.69-2-6.69-7v-37c0-5,2-7.08,6.69-7.08h24.77a2.53,2.53,0,0,1,2.62,2.82,2.59,2.59,0,0,1-2.62,2.89H665.09c-1.57,0-2.23.72-2.23,2.29v13.57h22.35a2.52,2.52,0,0,1,2.56,2.81,2.58,2.58,0,0,1-2.56,2.76H662.86Z" transform="translate(-204.6 -518.39)"/>
    <path d="M705.79,623.88a2.54,2.54,0,0,1-2.56,1.84,4.58,4.58,0,0,1-1.31-.2,2.9,2.9,0,0,1-2.29-2.68,3.38,3.38,0,0,1,.19-1.12L717.65,577a4.44,4.44,0,0,1,4.52-3.08,4.54,4.54,0,0,1,4.65,3.08l17.83,44.89a2.3,2.3,0,0,1,.26,1.12,2.85,2.85,0,0,1-2.36,2.62,3.7,3.7,0,0,1-1.18.2,2.72,2.72,0,0,1-2.68-1.84l-5.18-13.57H710.83Zm25.75-19-7.27-19.07a63.35,63.35,0,0,1-2-6.23,55.23,55.23,0,0,1-2,6.29l-7.41,19Z" transform="translate(-204.6 -518.39)"/>
    <path d="M771.39,622.57a4,4,0,0,1-4.33,3.15,4.14,4.14,0,0,1-4.39-3.21l-12.38-45.09a4.83,4.83,0,0,1-.13-.92c0-1.5,1.24-2.29,2.75-2.55a4.08,4.08,0,0,1,.92-.07,2.65,2.65,0,0,1,2.81,2.1l8.19,30.93c.92,3.47,1.77,9.5,2.36,12.85.59-3.41,1.51-9.31,2.43-12.78l7.8-30.22c.52-2,2-2.88,4.26-2.88s3.6.85,4.13,3l7.8,30.21c.85,3.28,1.7,9.37,2.29,12.78.65-3.54,1.64-9.5,2.49-12.71L806.65,576a2.75,2.75,0,0,1,3-2.1,3.49,3.49,0,0,1,.85.07c1.51.26,2.75,1,2.75,2.49a3,3,0,0,1-.13.78l-12.85,45.35a4.09,4.09,0,0,1-4.39,3.15,4,4,0,0,1-4.32-3.21L784,593.08c-1-4.06-1.77-9.76-2.36-13.37-.66,3.61-1.44,9.18-2.49,13.24Z" transform="translate(-204.6 -518.39)"/>
    <path d="M824,623.88a2.55,2.55,0,0,1-2.56,1.84,4.58,4.58,0,0,1-1.31-.2,2.9,2.9,0,0,1-2.29-2.68,3.38,3.38,0,0,1,.19-1.12L835.81,577a4.44,4.44,0,0,1,4.52-3.08A4.54,4.54,0,0,1,845,577l17.83,44.89a2.3,2.3,0,0,1,.26,1.12,2.85,2.85,0,0,1-2.36,2.62,3.7,3.7,0,0,1-1.18.2,2.71,2.71,0,0,1-2.68-1.84l-5.18-13.57H829Zm25.75-19-7.27-19.07a63.35,63.35,0,0,1-2-6.23,53.31,53.31,0,0,1-2,6.29l-7.4,19Z" transform="translate(-204.6 -518.39)"/>
    <path d="M900.3,575.19a2.63,2.63,0,0,1,2.36-1.37,3.6,3.6,0,0,1,1.76.52,3.11,3.11,0,0,1,1.77,2.62,2.54,2.54,0,0,1-.45,1.44l-17.31,26V623c0,1.77-1.31,2.69-3.08,2.69s-3.14-.92-3.14-2.69V604.36l-17.44-26.09a2.54,2.54,0,0,1-.39-1.31,3.34,3.34,0,0,1,1.9-2.69,4.46,4.46,0,0,1,1.77-.45,2.78,2.78,0,0,1,2.36,1.37l14.94,23.33Z" transform="translate(-204.6 -518.39)"/>
    <path d="M937.45,596.43c10.23,2.16,15.8,5.63,15.8,15.13,0,8.46-6.49,14.82-18.22,14.82-14.94,0-18-7.74-19.6-13a4.9,4.9,0,0,1-.13-.85,2.94,2.94,0,0,1,2.49-2.75,6.11,6.11,0,0,1,1.12-.13,2.81,2.81,0,0,1,2.88,2c1.18,4.4,3.34,9.31,12.91,9.31,7.47,0,12.12-3.6,12.12-9.24,0-5.37-2.94-7.73-10.68-9.31l-5.24-1.11c-9.83-2-14.29-5.77-14.29-14.35,0-6.88,5.11-13.57,17.43-13.57,11.8,0,15.87,6.29,17.31,11a2.66,2.66,0,0,1,.19,1,2.8,2.8,0,0,1-2.42,2.63A6.26,6.26,0,0,1,948,588a2.91,2.91,0,0,1-2.88-1.9c-1-3.74-3.8-7.41-11.08-7.41-7.6,0-11.07,3.8-11.07,8.13,0,4.72,2.29,7.14,9.63,8.58Z" transform="translate(-204.6 -518.39)"/>
  </svg>
);
